import React from "react";
import "./Home.scss";
import { motion } from "framer-motion";
import { Tooltip } from "react-tooltip";
import patterns from "../../assets/patterns.svg";
import Dock from "../../components/Dock/Dock";
import Logo from "../../components/logo/Logo";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <section className="home justify-center page">
      <Tooltip id="my-tooltip" className="tooltip-container" noArrow />
      <motion.img
        src={patterns}
        alt="patterns"
        className="bg-pattern"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1 }}
      />
      <div className="content">
        <Logo />
        <Dock />
      </div>
      <div className="mint-btn-wrapper justify-center">
        <Link to="/mint">
          <button className="primary-btn hover-border-10">
            Mint Artifacts
          </button>
        </Link>
      </div>
    </section>
  );
};

export default Home;
