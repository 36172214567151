import React, { useState } from "react";
import "./Nft.scss";
import plus from "../../../../assets/plus.png";
import minus from "../../../../assets/minus.png";
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi';
import { ABI, CONTRACT_ADDRESS } from "../../../../data/contract";
import { Toaster, toast } from 'sonner'
import { parseEther } from "viem";


const Nft = () => {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);

  const { address } = useAccount();


  const { data: simulatedContract } = useSimulateContract({
    address: CONTRACT_ADDRESS,
    abi: ABI,
    functionName: 'mint',
    /*  mint(uint32 qty,bytes32[] proof,uint64 timestamp,bytes signature) */
    args: [
      count,
      [],
      Math.floor(Date.now() / 1000),
      "0x"
    ]
  });

  const { writeContract } = useWriteContract();



  const load = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  const increaseCount = () => {
    if (count < 5) setCount(count + 1);
  };

  const decreaseCount = () => {
    if (count > 0) setCount(count - 1);
  };

  // eslint-disable-next-line no-undef

  return (
    <div className="nft">
      <Toaster />
      <img
        src="https://offline-oracle.s3.ap-south-1.amazonaws.com/assets/image/orb.png"
        alt="mint"
        className="mint-img"
      />
      <div className="counter">
        <img
          src={minus}
          alt="minus"
          onClick={decreaseCount}
          className="btn-img"
        />
        <p>{count}</p>
        <img
          src={plus}
          alt="plus"
          onClick={increaseCount}
          className="btn-img"
        />
      </div>
      <div className="counter total">
        <p>Total: {(0.025 * count).toFixed(3)} ETH </p>
      </div>
      {/* <p className="caution">5 max per wallet</p> */}
      <div className="justify-center">
        <button
          disabled={loading}
          className={`primary-btn ${loading ? "loading" : ""}`}
          onClick={async () => {
            setLoading(true);
            await writeContract({
              address: CONTRACT_ADDRESS,
              abi: ABI,
              functionName: 'mint',
              value: parseEther((0.025 * count).toFixed(3)),
              args: [
                count,
                [],
                Math.floor(Date.now() / 1000),
                '0x'
              ],
            },
              {
                onError: (error) => {
                  toast.error(error.shortMessage)
                  setLoading(false);
                },
                onSuccess: (data) => {
                  setLoading(false);
                  console.log(data)
                  toast.success('You have successfully Minted your Artifact')
                },

              })
          }
          }
        >
          <div className="loading-container">
            <div className="bar"></div>
          </div>
          Mint Now
        </button>
      </div>
    </div>
  );
};

export default Nft;
