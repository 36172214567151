import React, { useState } from "react";
import logos from "../../data/LogoData";
import { motion } from "framer-motion";

const Image = ({ src, alternative }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <img src={hovered ? alternative : src} alt="offline-oracles" />
    </div>
  );
};

const Logo = () => {
  return (
    <motion.div
      className="logo-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      {logos.map((value, index) => (
        <Image key={index} {...value} />
      ))}
    </motion.div>
  );
};

export default Logo;
