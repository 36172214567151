import React from "react";
import { motion } from "framer-motion";
import patterns from "../../assets/patterns-dark.svg";
import sqrpatt from "../../assets/sqrpatt.svg";
import BackButton from "../../components/back-button/BackButton";

const Artifacts = () => {
  return (
    <section className="justify-center page">
      <span className="button-container">
        <BackButton />
      </span>
      <motion.img
        src={patterns}
        alt="patterns"
        className="bg-pattern"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1.1 }}
        transition={{ duration: 1 }}
      />
      <div className="text-content">
        <header>
          <h1>
            Offline Oracles are the first Base NFT collection to launch on Magic Eden.
          
          </h1>
          <p>
            The NFT, called 'Artifact,' powers Offline Protocol and our foundational blockchain infrastructure.
            Artifacts serve as a token for product access, protocol rewards, active network validation, and the 
            ultimate quest of exploring the infinite blockspace to shape the future of decentralized economies. 
            This is the earliest and most powerful entry you can find into our ecosystem to pledge your support 
            and join this journey of impact.
          </p>
        </header>
        <motion.img
          src={sqrpatt}
          alt="patterns"
          className="content-pattern"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        />
      </div>
    </section>
  );
};

export default Artifacts;
