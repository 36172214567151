import React, { useRef } from "react";
import { motion, useMotionValue, useSpring, useTransform } from "framer-motion";
import dockItems from "../../data/DockItems";
import { Link } from "react-router-dom";

const Dock = () => {
  let mouseX = useMotionValue(Infinity);

  return (
    <motion.div
      onMouseMove={(e) => mouseX.set(e.pageX)}
      onMouseLeave={() => mouseX.set(Infinity)}
      className="dock"
    >
      {dockItems.map((value, i) => (
        <DockItem mouseX={mouseX} key={i} {...value} delay={i} />
      ))}
    </motion.div>
  );
};

const DockItem = ({
  mouseX,
  imgURL,
  altText,
  isRoute,
  route,
  delay,
  link,
  tooltip,
}) => {
  let ref = useRef(null);

  let distance = useTransform(mouseX, (val) => {
    let bounds = ref.current?.getBoundingClientRect() ?? { x: 0, width: 10 };

    return val - bounds.x - bounds.width / 2;
  });

  let widthSync = useTransform(distance, [-190, 0, 190], [80, 120, 80]);
  let width = useSpring(widthSync, { mass: 0.5, stiffness: 100, damping: 25 });

  return isRoute ? (
    <Link
      to={route}
      data-tooltip-id="my-tooltip"
      data-tooltip-content={tooltip}
      data-tooltip-place="bottom"
    >
      {" "}
      <motion.img
        src={imgURL}
        alt={altText}
        ref={ref}
        style={{ width }}
        className="dock-img"
        initial={{ opacity: 0, scale: 1.2 + delay / 6 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: (delay + 2) / 6 }}
      />
    </Link>
  ) : (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      data-tooltip-id="my-tooltip"
      data-tooltip-content={tooltip}
      data-tooltip-place="bottom"
    >
      <motion.img
        src={imgURL}
        alt={altText}
        ref={ref}
        style={{ width }}
        className="dock-img"
        initial={{ opacity: 0, scale: 1.2 + delay / 6 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: (delay + 2) / 6 }}
      />
    </a>
  );
};

export default Dock;
