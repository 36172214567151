import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { http } from 'viem';
import { base } from 'viem/chains';
import { PrivyProvider } from '@privy-io/react-auth';
import { WagmiProvider, createConfig } from '@privy-io/wagmi';

const queryClient = new QueryClient();

export const wagmiConfig = createConfig({
    chains: [base],
    transports: {
        [base.id]: http(),
    },
});

const privyConfig = {
    loginMethods: ['wallet'],
    appearance: {
        showWalletLoginFirst: true,
    },
    defaultChain: base,
    supportedChains: [base],
};

export default function Providers({ children }) {
    return (
        <PrivyProvider
            appId={'clvmhck7y0al4tmusccngvwik'}
            config={privyConfig}
        >
            <QueryClientProvider client={queryClient}>
                <WagmiProvider config={wagmiConfig} reconnectOnMount={false}>
                    {children}
                </WagmiProvider>
            </QueryClientProvider>
        </PrivyProvider>
    );
}