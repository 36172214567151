import React, { useEffect, useMemo } from "react";
import { motion } from "framer-motion";
import "./Mint.scss";
import patterns from "../../assets/patterns-dark.svg";
import BackButton from "../../components/back-button/BackButton";
import MintCard from "./components/mint-card/MintCard";
import { WalletContext } from "../../context/context";
import walletDot from "../../assets/wallet-dot.png";
import logoutIcon from "../../assets/logout.svg";
import { useAccount, useDisconnect } from 'wagmi';
import { usePrivy } from '@privy-io/react-auth';

const Mint = () => {
  const { ready, user, authenticated, login, connectWallet, logout, linkWallet } = usePrivy();

  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();

  const value = useMemo(() => {
    return {
      wallet: {
        connected: isConnected,
        walletAddress: address,
      },
    };
  }, [isConnected, address]);

  // useEffect(() => {
  //   if (!isConnected) {
  //     localStorage.removeItem("walletInfo");
  //   }
  // }, [isConnected]);

  if (!ready) {
    return null;
  }

  // const [wallet, setWallet] = useState({
  //   connected: false,
  //   walletAddress: "",
  // });

  // useEffect(() => {
  //   const storedValue = JSON.parse(localStorage.getItem("walletInfo"));
  //   if (storedValue) {
  //     setWallet(storedValue);
  //   }
  // }, []);

  // const storeWalletValue = (walletInfo) => {
  //   setWallet(walletInfo);
  //   localStorage.setItem("walletInfo", JSON.stringify(walletInfo));
  // };





  // const logout = () => {
  //   setWallet({
  //     connected: false,
  //     walletAddress: "",
  //   });
  //   localStorage.removeItem("walletInfo");
  // };

  return (
    <WalletContext.Provider value={value}>
      <section className="justify-center page mint">
        <span className="button-container">
          <BackButton />
        </span>
        {isConnected && authenticated && (
          <div
            className="wallet">
            <img src={walletDot} alt="wallet-dot" />
            <p>{address?.slice(0, 4) + '...' + address?.slice(-4)}</p>
            <img
              src={logoutIcon}
              alt="logout"
              onClick={async () => {
                await logout();
                disconnect();

              }}
              className="btn-img"
            />
          </div>
        )}
        <motion.img
          src={patterns}
          alt="patterns"
          className="bg-pattern"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1.1 }}
          transition={{ duration: 1 }}
        />
        <div className="text-content justify-center">
          <MintCard />
          <video className="bg-video" autoPlay loop muted>
            <source
              src="https://offline-oracle.s3.ap-south-1.amazonaws.com/assets/video/Oracles.mp4"
              type="video/mp4"
            />
          </video>
          <div className="overlay"></div>
        </div>
      </section>
    </WalletContext.Provider>
  );
};

export default Mint;
