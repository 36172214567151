import React from "react";
import "./styles/main.scss";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/home/Home";
import Artifacts from "./pages/artifacts/Artifacts";
import Mint from "./pages/mint/Mint";
import Providers from "./components/provider";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/artifacts",
    element: <Artifacts />,
  },
  {
    path: "/mint",
    element: <Mint />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Providers>
      <RouterProvider router={router} />
    </Providers>
  </React.StrictMode>
);

reportWebVitals();
