import A1 from "../assets/1st-white.png";
import A1_Alt from "../assets/1st-colored.png";
import A2 from "../assets/2nd-white.png";
import A2_Alt from "../assets/2nd-colored.png";
import A3 from "../assets/3rd-white.png";
import A3_Alt from "../assets/3rd-colored.png";
import A4 from "../assets/4th-white.png";
import A4_Alt from "../assets/4th-colored.png";
import A5 from "../assets/5th-white.png";
import A5_Alt from "../assets/5th-colored.png";
import A6 from "../assets/6th-white.png";
import A6_Alt from "../assets/6th-colored.png";
import A7 from "../assets/7th-white.png";
import A7_Alt from "../assets/7th-colored.png";

const logos = [
  {
    src: A1,
    alternative: A1_Alt,
  },
  {
    src: A2,
    alternative: A2_Alt,
  },
  {
    src: A3,
    alternative: A3_Alt,
  },
  {
    src: A4,
    alternative: A4_Alt,
  },
  {
    src: A5,
    alternative: A5_Alt,
  },
  {
    src: A6,
    alternative: A6_Alt,
  },
  {
    src: A7,
    alternative: A7_Alt,
  },
];

export default logos;