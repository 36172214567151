import picture from "../assets/picture.svg";
import machine from "../assets/machine.svg";
import twitterBird from "../assets/twitter-bird.svg";
import ticket from "../assets/ticket.svg";
import diamond from "../assets/diamond.svg";
import interfaceIcon from "../assets/interface.svg";
import Magiceden from "../assets/me.svg";

const dockItems = [
  {
    imgURL: picture,
    altText: "artifacts",
    isRoute: true,
    route: "/artifacts",
    tooltip: "Artifacts",
  },
  {
    imgURL: diamond,
    altText: "business product",
    isRoute: false,
    link: "https://www.wallets.offlineoracles.com/",
    tooltip: "Wallet Checker",
  },
  {
    imgURL: machine,
    altText: "entertainment event",
    isRoute: false,
    link: "https://arcade.offlineoracles.com",
    tooltip: "Oracles Arcade ",
  },
  {
    imgURL: ticket,
    altText: "entertainment events",
    isRoute: false,
    link: "https://zora.co/collect/base:0x70c7f41545556dc812a3f58cc16844c7745fe2ab/1?referrer=0xA31AB374bcBeD09",
    tooltip: "Free Mint on Zora",
  },
  {
    imgURL: interfaceIcon,
    altText: "interface essentials",
    isRoute: false,
    link: "https://offlineprotocol.com",
    tooltip: "Offline Protocol",
  },
  {
    imgURL: twitterBird,
    altText: "twitter bird",
    isRoute: false,
    link: "https://twitter.com/OfflineOracles",
    tooltip: "Twitter",
  },
  /*{
    imgURL: Magiceden,
    altText: "Magic Eden",
    isRoute: false,
    link: "https://magiceden.io/launchpad/base/offline_oracles",
    tooltip: "Magic eden",
  },*/
];

export default dockItems;
