import React, { useEffect, useState } from "react";
import "./MintCard.scss";
import grid from "../../../../assets/Grid.svg";
import logo from "../../../../assets/logo.png";
import Nft from "../nft/Nft";
import { useAccount } from 'wagmi';

import { usePrivy } from '@privy-io/react-auth';

const MintCard = () => {
  const [loading, setLoading] = useState(false);

  const { ready, authenticated, login } = usePrivy();

  // WAGMI hooks
  const { address, isConnected } = useAccount();


  const load = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    if (isConnected) {
      load();
    }
  }, [isConnected]);



  if (!ready) {
    return null;
  }


  return (
    <div className="mint-card">
      <img src={grid} alt="grid" className="grid" />
      <div className="gradient"></div>
      {(!address || !authenticated) && (
        <div className="content wallet-not-connected">
          <div className="marquee">
            <div className="marquee-content">
              <div className="marquee-item">
                <img src={logo} alt="logo" />
              </div>
              <div className="marquee-item">
                <img src={logo} alt="logo" />
              </div>
              <div className="marquee-item">
                <img src={logo} alt="logo" />
              </div>
              <div className="marquee-item">
                <img src={logo} alt="logo" />
              </div>
            </div>
          </div>
          <p> Connect your wallet to mint an Artifact</p>
          <div className="justify-center">
            <button
              className={`primary-btn ${loading ? "loading" : ""}`}
              onClick={login}
            >
              <div className="loading-container">
                <div className="bar"></div>
              </div>
              Connect Wallet
            </button>
          </div>
        </div>
      )}

      {isConnected && authenticated && (
        <div className="content wallet-connected">
          <Nft />
        </div>
      )}
    </div>
  );
};

export default MintCard;
