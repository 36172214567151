import React from "react";
import { Link } from "react-router-dom";
import "./BackButton.scss";

const BackButton = () => {
  return (
    <Link to="/">
      <button className="back-button">
        <span className="material-symbols-outlined">arrow_back</span>
      </button>
    </Link>
  );
};

export default BackButton;
